<template>
  <div class="pbf">
     <BrandTabDiaries
        v-if="type == 'brands'"
        :inurl="`/${props.type}/${props.id}/diaries`"
        :brand-id="props.id"
        :intags="tags"
      />
     <BrandTabDiaries
        v-else
        :inurl="`/${props.type}/${props.id}/diaries`"
        :product-id="props.id"
        :intags="tags"
      />
  </div>
</template>

<script setup>
const {$api, $tagsUtil, $ga} = useNuxtApp()

const props = defineProps({
  id: {
    type: Number
  },
  type: {
    type: String
  }
})
const tags = ref($tagsUtil.tagsBrandSeedDiaries)
</script>

<style scoped>

</style>